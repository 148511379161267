import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
import URLConstants from "../../constants/urlConstants";
import { ChatbotConfigurationType } from '../../@types/chatbotConfigurationType';

type GetChatbotState = {
  isLoading: boolean;
  error: boolean; 
  weddingAdvance:ChatbotConfigurationType[]; 
  campaigns:ChatbotConfigurationType[]; 
}

const initialState: GetChatbotState = {
  isLoading: false,
  error: false, 
  weddingAdvance: [],
  campaigns: [],
}

const slice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

  
    getChatbotWeddingAdvanceData(state, action) {
      state.isLoading = false;
      state.weddingAdvance = action.payload;
    },
    getChatbotCampaignsData(state, action) {
      state.isLoading = false;
      state.campaigns = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getWeddingAdvanceChatbotList(whatsAppBusinessId:string,values:any) {
  var params = {
    "from":values.from,
    "to":values.to,
    "isEnabled" : values.isEnabled,
    "keyword" : "wedding-advance"
  }
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await axios.get(`${URLConstants.CHATBOT_CONFIGURATIONS_LIST_BASE_URL}/${whatsAppBusinessId}`,{params} );
          dispatch(slice.actions.getChatbotWeddingAdvanceData(response.data.data));
        } catch (error:any) {
            return {message : error?.message, hasError : true} 
        }
    };
  }  

  export function getCampaignsChatbotList(whatsAppBusinessId:string,values:any) {
    var params = {
      "from":values.from,
      "to":values.to,
      "isEnabled" : values.isEnabled,
      "keyword" : "campaigns"
    }
      return async () => {
          dispatch(slice.actions.startLoading());
          try {
            const response = await axios.get(`${URLConstants.CHATBOT_CONFIGURATIONS_LIST_BASE_URL}/${whatsAppBusinessId}`,{params} );
            dispatch(slice.actions.getChatbotCampaignsData(response.data.data));
          } catch (error:any) {
              return {message : error?.message, hasError : true} 
          }
      };
    }  

export function createTextConfiguration(whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, text:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post( URLConstants.CREATE_CHATBOT_CONFIGURATIONS_BASE_URL , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, text, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}  
export function createImageConfiguration(whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, image:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post( URLConstants.CREATE_CHATBOT_CONFIGURATIONS_BASE_URL , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, image, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}
export function createVideoConfiguration(whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, video:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post( URLConstants.CREATE_CHATBOT_CONFIGURATIONS_BASE_URL , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, video, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}
export function createDocumentConfiguration(whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, document:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post( URLConstants.CREATE_CHATBOT_CONFIGURATIONS_BASE_URL , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, document, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}

export function enableOrDisableChatbot(values:any) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
          const response =  await axios.patch(URLConstants.CREATE_CHATBOT_CONFIGURATIONS_BASE_URL,values);
         // dispatch(slice.actions.createChatBotSuccess(response.data.data));
      } catch (error:any) {
          dispatch(slice.actions.hasError(error));
          return {message : error?.message, hasError : true} 
      }
  };
}

export function editTextConfiguration(botConfigurationId: string, whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, text:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put( URLConstants.EDIT_CHATBOT_CONFIGURATIONS_BASE_URL + botConfigurationId , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, text, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
} 


export function editImageConfiguration(botConfigurationId: string, whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, image:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put( URLConstants.EDIT_CHATBOT_CONFIGURATIONS_BASE_URL + botConfigurationId , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, image, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}  


export function editVideoConfiguration(botConfigurationId: string, whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, video:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put( URLConstants.EDIT_CHATBOT_CONFIGURATIONS_BASE_URL + botConfigurationId , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, video, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}  

export function editDocumentConfiguration(botConfigurationId: string, whatsAppBusinessId:string, parentbotConfigurationId:any, keywords:string,parameterConfigurationType: any, messageType:string, document:any, level:number,from: any, to:any,isEnabled : boolean, order:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put( URLConstants.EDIT_CHATBOT_CONFIGURATIONS_BASE_URL + botConfigurationId , {whatsAppBusinessId, parentbotConfigurationId, keywords,parameterConfigurationType, messageType, document, level,from, to, isEnabled, order});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}  