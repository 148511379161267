import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
import URLConstants from "../../constants/urlConstants";

type GetTemplatesState = {
  isLoading: boolean;
  error: boolean;
  blockContact: [];
  
}

type AddContactValues={
  contactFirstName: string,
  contactLastName: string,
  contactEmail: string,
  contactPhoneNumber: string,
}
const initialState: GetTemplatesState = {
  isLoading: false,
  error: false,
  blockContact: [],
 
}

const slice = createSlice({
  name: "blockContact",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Block CONTACT
    blockSuccess(state, action) {
      state.isLoading = false;
      state.blockContact = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getContactBlock(page:number, records: number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(URLConstants.GET_BLOCK_CONTACT_BASE_URL+`?page=${page}&records=${records}`);
        dispatch(slice.actions.blockSuccess(response.data.data));	
      } catch (error:any) {
          dispatch(slice.actions.hasError(error));
          return {message : error?.message, hasError : true} 
      }
  };
}

export function contactBlock(contactId:any) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.patch(`${URLConstants.BLOCK_CONTACT_BASE_URL}/${contactId}/block`,{'blocked':true});
        dispatch(slice.actions.blockSuccess(response.data.data));	
      } catch (error:any) {
          dispatch(slice.actions.hasError(error));
          return {message : error?.message, hasError : true} 
      }
  };
}

export function contactUnBlock(contactId:any) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.patch(`${URLConstants.BLOCK_CONTACT_BASE_URL}/${contactId}/block`,{'blocked':false});
        dispatch(slice.actions.blockSuccess(response.data.data));	
      } catch (error:any) {
          dispatch(slice.actions.hasError(error));
          return {message : error?.message, hasError : true} 
      }
  };
}

export function directlyBlockContact(values:AddContactValues) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(URLConstants.DIRECTLY_BLOCK_CONTACT_BASE_URL,{'contactFirstName' :values.contactFirstName,"contactLastName":values.contactLastName,"contactEmail": values.contactEmail,"contactPhoneNumber":values.contactPhoneNumber});
        dispatch(slice.actions.blockSuccess(response.data.data));	
      } catch (error:any) {
          dispatch(slice.actions.hasError(error));
          return {message : error?.message, hasError : true} 
      }
  };
}