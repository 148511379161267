import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
import URLConstants from "../../constants/urlConstants";

type GetTemplatesState = {
  isLoading: boolean;
  error: boolean; 
  goldRate:any; 
}

const initialState: GetTemplatesState = {
  isLoading: false,
  error: false, 
  goldRate: {},
}

const slice = createSlice({
  name: "goldRate",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Submit GoldRate
    getGoldRateData(state, action) {
      state.isLoading = false;
      state.goldRate = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getGoldRate(botConfigurationId:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await axios.get( URLConstants.GOLD_RATE_BASE_URL + botConfigurationId );
          dispatch(slice.actions.getGoldRateData(response.data.data));
        } catch (error:any) {
            return {message : error?.message, hasError : true} 
        }
    };
  }  

export function submitGoldRate(botConfigurationId:string, parentbotConfigurationId:string, whatsAppBusinessId:string, keywords:string, messageType:string, text:any, level:number) {
  return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put( URLConstants.GOLD_RATE_BASE_URL + botConfigurationId , {parentbotConfigurationId, whatsAppBusinessId, keywords, messageType, text, level});
      } catch (error:any) {
          return {message : error?.message, hasError : true} 
      }
  };
}  
