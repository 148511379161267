import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { DownloadAnalyticsType } from '../../@types/downloadAnalyticsType';
import URLConstants from '../../constants/urlConstants';


type DownloadAnalyticsState = {
    isLoading: boolean;
    error: boolean;
    downloadAnalytics: DownloadAnalyticsType;
}

const initialState: DownloadAnalyticsState = {
    isLoading: false,
    error: false,
    downloadAnalytics: { } as DownloadAnalyticsType
}

const slice = createSlice({
    name: 'downloadAnalytics',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET  ANALYTICS FILE LINK OF MESSAGES
        getMessageAnalyitcsDownloadSuccess(state, action) {
            state.isLoading = false;
            state.downloadAnalytics = action.payload;
        },

        // GET  ANALYTICS FILE LINK OF CAMPAIGN
        getCampaignAnalyitcsDownloadSuccess(state, action) {
            state.isLoading = false;
            state.downloadAnalytics = action.payload;
        },
        // GET  ANALYTICS FILE LINK OF CAMPAIGN
        apiCampaignAnalyitcsDownloadSuccess(state, action) {
            state.isLoading = false;
            state.downloadAnalytics = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getMessagesAnalyticsFileDownloadLink(from:string,to :string) {
    // let fromDate = new Date(from);
    // let tempFrom = new Date(fromDate.setDate(fromDate.getDate() - 1)).toISOString().slice(0, 10);
    // let toDate = new Date(to);
    // let tempTo = new Date(toDate.setDate(toDate.getDate() + 1)).toISOString().slice(0, 10);
    var params = {
        "from": from,
        "to": to
    }
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(URLConstants.DOWNLOAD_ANALYTICS_BASE_URL,{params});
                window.location.href =  response.data.data.url
                dispatch(slice.actions.getMessageAnalyitcsDownloadSuccess(response.data.data));
            } catch (error:any) {
                dispatch(slice.actions.hasError(error));
                return {message : error?.message, hasError : true} 
            }
        };
}

export function getCampaignAnalyticsFileDownloadLink(campaignId:string) {
    var params = {
        "campaignId": campaignId
      }
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(URLConstants.DOWNLOAD_ANALYTICS_BASE_URL,{params});
                window.location.href =  response.data.data.url
                dispatch(slice.actions.getCampaignAnalyitcsDownloadSuccess(response.data.data));
            } catch (error:any) {
                dispatch(slice.actions.hasError(error));
                return {message : error?.message, hasError : true} 
            }
        };
}

export function apiCampaignAnalyticsFileDownloadLink(from:string,to :string, isApiKey:boolean) {
    var params = {
        "from": from,
        "to": to,
        "isApiKey":isApiKey
      }
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(URLConstants.DOWNLOAD_ANALYTICS_BASE_URL,{params});
                window.location.href =  response.data.data.url
                dispatch(slice.actions.apiCampaignAnalyitcsDownloadSuccess(response.data.data));
            } catch (error:any) {
                dispatch(slice.actions.hasError(error));
                return {message : error?.message, hasError : true} 
            }
        };
}